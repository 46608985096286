<template>
  <div
    v-if="(showFitType || showFabric) && productIntroData"
    class="product-intro__fitAndFabric"
  >
    <div
      v-if="showFitType"
      class="product-intro__fitAndFabric-containr"
    >
      <div
        ref="div1"
        class="product-intro__fitAndFabric-title"
        :style="{ fontSize: isModal ? '16px' : '14px' }"
      >
        {{ language.SHEIN_KEY_PC_17747 }}
      </div>
      <div
        class="product-intro__fitAndFabric-dec"
      >
        <div
          v-for="(item, index) in fitType"
          :key="index"
        >
          <div
            class="product-intro__fitAndFabric-pos"
            :class="{
              'product-intro__fitAndFabric-posR': fitType.length - 1 === index,
              'product-intro__fitAndFabric-posC':
                fitType.length - 1 !== index && index !== 0,
              'product-intro__fitAndFabric-posL': index === 0,
              'product-intro__fitAndFabric-select': showFitType.key === item.key,
            }"
          >
            <div
              v-show="showFitType.key === item.key"
              class="product-intro__fitAndFabric-icon"
            ></div>
            <div
              v-if="item.value"
              class="product-intro__fitAndFabric-text"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showFabric"
      class="product-intro__fitAndFabric-containr"
    >
      <div
        ref="div2"
        class="product-intro__fitAndFabric-title"
        :style="{ fontSize: isModal ? '16px' : '14px' }"
      >
        {{ language.SHEIN_KEY_PC_18014 }}
      </div>
      <div
        class="product-intro__fitAndFabric-dec"
      >
        <div
          v-for="(item, index) in fabric"
          :key="index"
        >
          <div
            class="product-intro__fitAndFabric-pos"
            :class="{
              'product-intro__fitAndFabric-posR': fabric.length - 1 === index,
              'product-intro__fitAndFabric-posC':
                fabric.length - 1 !== index && index !== 0,
              'product-intro__fitAndFabric-posL': index === 0,
              'product-intro__fitAndFabric-select': showFabric.key === item.key,
            }"
          >
            <div
              v-show="showFabric.key === item.key"
              class="product-intro__fitAndFabric-icon"
            ></div>
            <div
              v-if="item.value"
              class="product-intro__fitAndFabric-text"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isModal: Boolean,
    productIntroData: {
      default: () => {},
      type: Object,
    },
    language: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    fitType() {
      return [
        {
          key: 'Skinny',
          value: this.language.SHEIN_KEY_PC_17719,
        },
        {
          key: 'Slim Fit',
          value: '',
        },
        {
          key: 'Regular Fit',
          value: this.language.SHEIN_KEY_PC_17720,
        },
        {
          key: 'Loose',
          value: '',
        },
        {
          key: 'Oversized',
          value: this.language.SHEIN_KEY_PC_17721,
        },
      ]
    },
    fabric() {
      return [
        {
          key: 'Non-Stretch',
          value: this.language.SHEIN_KEY_PC_18015,
        },
        {
          key: 'Slight Stretch',
          value: this.language.SHEIN_KEY_PC_18016,
        },
        {
          key: 'Medium Stretch',
          value: this.language.SHEIN_KEY_PC_18017,
        },
        {
          key: 'High Stretch',
          value: this.language.SHEIN_KEY_PC_18018,
        },
      ]
    },
    showFitType() {
      if (!this.productIntroData?.detail) return
      const multiPartProductDetails = this.productIntroData.detail
        .isMultiPartProduct
        ? (this.productIntroData.detail.productDetails || []).concat(
          this.productIntroData.detail.multiPartInfo.reduce(function (
            prev,
            item
          ) {
            prev.push(...(item.attributeList || []))
            return prev
          },
          [])
        )
        : this.productIntroData.detail.productDetails || []
      const find = multiPartProductDetails.find(
        (i) => i.attr_name_en === 'Fit Type'
      )
      const findIt = find && find['attr_value_en']
      return this.fitType.find((i) => i.key === findIt)
    },
    showFabric() {
      if (!this.productIntroData?.detail) return
      const multiPartProductDetails = this.productIntroData.detail
        .isMultiPartProduct
        ? (this.productIntroData.detail.productDetails || []).concat(
          this.productIntroData.detail.multiPartInfo.reduce(function (
            prev,
            item
          ) {
            prev.push(...(item.attributeList || []))
            return prev
          },
          [])
        )
        : this.productIntroData.detail.productDetails || []
      const findFabric = multiPartProductDetails.find(i => i.attr_name_en === 'Fabric')
      const findFabricIt = findFabric && findFabric['attr_value_en']
      if(findFabricIt) {
        return this.fabric.find(i => i.key === findFabricIt)
      }
      const findStretch = multiPartProductDetails.find(i => i.attr_name_en === 'Stretch')
      const findStretchIt = findStretch && findStretch['attr_value_en']
      if(findStretchIt) {
        return this.fabric.find(i => i.key === findStretchIt)
      }
      return false
    },
  },
  watch: {
    showFitType(){
      this.updateWidth()
    },
    showFabric(){
      this.updateWidth()
    }
  },
  mounted() {
    this.updateWidth()
  },
  methods: {
    updateWidth() {
      this.$nextTick(() => {
        if(this.$refs.div1 && this.$refs.div2) {
          // 重置宽度以确保offsetWidth反映自然内容宽度
          this.$refs.div1.style.width = 'auto'
          this.$refs.div2.style.width = 'auto'

          const div1Width = this.$refs.div1.offsetWidth
          const div2Width = this.$refs.div2.offsetWidth
          const maxWidth = Math.max(div1Width, div2Width)
          // 增加3像素，防止计算偏差
          const width = maxWidth + 3
          // 现在设置最大宽度到两个元素
          this.$refs.div1.style.width = `${width}px`
          this.$refs.div2.style.width = `${width}px`
        }else{
          this.$refs.div1 && (this.$refs.div1.style.width = 'auto')
          this.$refs.div2 && (this.$refs.div2.style.width = 'auto')
        }
      })
    }
  }
}
</script>

<style lang="less">
.product-intro {
  &__size-modal .c-modal .modal-dialog {
    &.modal-full {
      max-width: 1000px;
      top: 50%;
      transform: translate(-50%, -50%) /*rtl:ignore*/;
      margin: 0;
    }
    .common-sizemeasure__detail {
      min-width: 400px;
      flex-direction: row;
    }
    .modal-content {
      padding: 32px 48px;
      .padding-r(38px);
    }
    .modal-body {
      max-height: 420px;
      width: 620px;
      overflow-y: auto;
      .padding-r(10px);
      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
  &__fitAndFabric {
    margin-bottom: 30px;
    font-size: 12px;
    border-bottom: 0.5px solid #E5E5E5;
  }
  &__fitAndFabric-containr {
    display: flex;
    align-items: flex-end;
    padding-top: 18px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 24px;
    }
  }
  &__fitAndFabric-title {
    font-weight: 700;
    color: #222;
    padding-right: 20px;
    word-wrap: break-word;
    margin-bottom: -4px;
  }
  &__fitAndFabric-dec {
    flex: 1;
    display: flex;
    border-bottom: 2px solid #E5E5E5;
    justify-content: space-between;
    &.product-intro__fitAndFabric-text {
      border-top: none;
      margin: 0;
      padding: 0;
      margin-bottom: 24px;
    }
    div {
      position: relative;
    }
    span {
      position: absolute;
      top: 15px;
      white-space: nowrap;
    }
  }
  &__fitAndFabric-pos {
    position: absolute;
    display: block;
    background: #E5E5E5;
    width: 2px;
    height: 4px;
    top: 0px;
    .product-intro__fitAndFabric-text {
      bottom: 10px;
      position: absolute;
      white-space: nowrap;
    }
    &.product-intro__fitAndFabric-select {
      background: none;
    }
  }
  &__fitAndFabric-icon {
    width: 20px;
    height: 6px;
    background: #000;
  }
  &__fitAndFabric-posR {
    right: 0;
    .product-intro__fitAndFabric-icon {
      .left(-18px);
    }
    .product-intro__fitAndFabric-text {
      .right(0);
      text-align: right;
    }
  }
  &__fitAndFabric-posC {
    transform: translateX(-50%);
    left: 50%;
    .product-intro__fitAndFabric-icon {
      .left(-9px);
    }
    .product-intro__fitAndFabric-text {
      transform: translateX(-50%);
      left: 50%;
      text-align: center;
    }
  }
}
</style>
