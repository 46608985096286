<template>
  <div
    v-if="value"
    class="la-ball-pulse"
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'Loaders',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
