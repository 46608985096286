<template>
  <div
    v-if="productIntroData"
    class="common-sizeinfo"
    :class="{ 'is-modal': isModal }"
  >
    <div
      v-show="showBaseSize"
      class="common-sizetable"
    >
      <div
        v-if="tableTitle"
        class="common-sizetable__baszGuide"
        :style="{ fontSize: showMeasurement ? '18px' : '12px' }"
      >
        {{ tableTitle }}
      </div>
      <div
        v-else
        class="common-sizetable__title"
      >
        <div
          v-enterkey
          class="common-sizetable__title-s"
          :class="{ active: tableStatus == 'normal' }"
          :style="{ 
            fontSize: showMeasurement ? '18px' : '12px', 
            order: initTableStatus == 'normal' ? 0 : 1,
            ...(initTableStatus == 'normal' ? rtlStyle : 0), 
          }"
          da-event-click="1-8-6-82"
          data-type="product"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PC_17724"
          @click="changeTableStatus('normal')"
        >
          {{ language.SHEIN_KEY_PC_17724 }}
        </div>
        <div
          v-enterkey
          class="common-sizetable__title-s"
          :class="{ active: tableStatus == 'base' }"
          :style="{ 
            fontSize: showMeasurement ? '18px' : '12px', 
            order: initTableStatus == 'base' ? 0 : 1,
            ...(initTableStatus == 'base' ? rtlStyle : 0), 
          }"
          da-event-click="1-8-6-82"
          data-type="body"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PC_17722"
          @click="changeTableStatus('base')"
        >
          {{ language.SHEIN_KEY_PC_17722 }}
        </div>
      </div>

      <div class="common-sizetable__out-wrap">
        <div
          v-show="!showMeasurement"
          class="common-sizetable__guide"
        >
          <!-- S 尺码单位选择 -->
          <div class="common-sizetable__button">
            <div class="common-sizetable__button-inner">
              <span
                v-enterkey
                class="common-sizetable__button-item"
                :class="{
                  'common-sizetable__button-item_active': currentSizeUnit === 0,
                }"
                :style="{ borderRight: currentSizeUnit === 0 ? '' : 'none' }"
                da-event-click="1-8-6-64"
                data-unit="CM"
                aria-label="CM"
                tabindex="0"
                @click="handleChangeUnit(0)"
              >
                CM
              </span>
              <span
                v-enterkey
                class="common-sizetable__button-item"
                :class="{
                  'common-sizetable__button-item_active': currentSizeUnit === 1,
                }"
                :style="{ borderLeft: currentSizeUnit === 1 ? '' : 'none' }"
                da-event-click="1-8-6-64"
                data-unit="IN"
                aria-label="IN"
                tabindex="0"
                @click="handleChangeUnit(1)"
              >
                IN
              </span>
            </div>
          </div>
          <!-- E 尺码单位选择 -->
          <!-- S 多国本地尺码选择 -->
          <div
            v-if="multiLocalSize &&
              Object.keys(multiLocalSize).length &&
              !showMeasurement &&
              showBaseSize &&
              currentSelectedCountry &&
              multiLocalSize[currentSelectedCountry]
            "
            class="common-sizetable__units"
          >
            <div
              class="common-sizetable__country"
              @click.stop="multiSelectOption = !multiSelectOption"
            >
              {{ currentSelectedCountry }} {{ language.SHEIN_KEY_PC_14656
              }}<i
                class="svgicon svgicon-down_222"
                :class="[multiSelectOption ? 'rotate-up' : '']"
              ></i>
            </div>
            <ul
              v-show="multiSelectOption"
              @mouseleave="multiSelectOption = false"
            >
              <li
                v-for="(value, key) in multiLocalSize"
                :key="key"
                :class="{
                  'common-sizetable__country-select':
                    key === currentSelectedCountry,
                }"
                da-event-click="1-8-6-62"
                :data-country="currentSelectedCountry"
                @mouseenter="selectCountry(key)"
                @click="multiSelectOption = false"
              >
                {{ key }}
              </li>
            </ul>
          </div>
          <!-- E 多国本地尺码选择 -->
        </div>

        <ul
          v-if="multiPartList.length > 1"
          v-show="tableStatus == 'normal'"
          class="sg-table__part"
          :class="
            !showMeasurement ? 'sg-table__part-line' : 'sg-table__part-btn'
          "
        >
          <div class="sg-table__part-wrap">
            <li
              v-for="item in multiPartList"
              :key="item.code"
              :class="{ 'part-active': currMultiPart.code === item.code }"
              @click="selectMultiPart = item"
            >
              {{ item.name }}
            </li>
          </div>
        </ul>
      </div>

      <!-- 基码表 -->
      <div class="common-sizetable__table j-common-size-table">
        <div
          class="common-sizetable__table-container"
          :style="{width: tableContainerWidth}"
          @scroll="handleScroll"
        >
          <table
            v-show="tableHeadBase.length && tableBodyBase.result.length"
            class="j-cm-table common-sizetable__table-content"
            cellspacing="0"
            cellpadding="0"
          >
            <template v-if="tableHeadBase.length">
              <tr class="cm-inch common-sizetable__table-tr trhead">
                <template v-for="(item, index) in tableHeadBase">
                  <td
                    v-if="
                      !(!hasSizeColumn && item.value === headName) &&
                        tableBodyBase.nullMap[index]
                    "
                    :key="item.value"
                    :class="{ 'common-sizetable__table-td-shadow': index == 0 && showShadow }"
                  >
                    {{ item.value }}
                  </td>
                </template>
              </tr>
            </template>
            <template v-if="tableBodyBase.result.length">
              <template v-for="(item, index) in tableBodyBase.result">
                <tr
                  v-if="item.length == tableHeadBase.length"
                  :key="index"
                  :class="['common-sizetable__table-tr', {'common-sizetable__table-tr-yellow': isRecommendedSize(item)}]"
                >
                  <template v-for="(value, key) in item">
                    <td
                      v-if="tableBodyBase.nullMap[key] && value"
                      :key="key"
                      :aria-label="`${tableHeadBase[key].value}:${value}`"
                      tabindex="0"
                      :class="{ 'common-sizetable__table-td-shadow': key == 0 && showShadow }"
                    >
                      <Icon
                        v-if="isRecommendedSize(item) && key == 0"
                        style="vertical-align: -2px; margin-right: 2px;"
                        name="sui_icon_like_completed_18px"
                        size="14px"
                      />
                      {{ key == 0 ? value : filterUnit(value) }}
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>
      <div
        v-show="!showMeasurement"
        :aria-label="sizeTipsText"
        tabindex="0"
        class="common-sizetable__tip"
      >
        {{
          '*' + sizeTipsText
        }}
      </div>
      <div
        v-show="showMeasurement"
        class="common-sizetable__notice"
        :style="{ 'margin-top': '10px' }"
      >
        {{
          '*' + sizeTipsText
        }}
      </div>
      <div
        v-if="isJapan"
        class="common-sizetable__notice"
        :style="{ 'margin-top': '10px' }"
      >
        {{ language.SHEIN_KEY_PC_31242 }}
      </div>
      <div
        v-if="!showMeasurement && !parentCatShowMeasure"
        class="common-sizetable__more"
        @click="sizeGuideModalShow"
      >
        {{ language.SHEIN_KEY_PC_18044 }}
      </div>
      <!-- E 尺码表格 -->
    </div>

    <div
      v-if="
        ((parentCatShowMeasure && !showMeasurement) || showMeasurement) &&
          hasMeasurementData &&
          tableStatus == 'normal'
      "
      class="common-sizemeasure__normal"
    >
      <div class="common-sizemeasure__title">
        <p
          :style="{
            fontSize: showMeasurement ? '16px' : '12px',
            fontWeight: '400',
          }"
        >
          {{
            showMeasurement
              ? language.SHEIN_KEY_PC_18043
              : language.SHEIN_KEY_PC_18193
          }}
        </p>
        <Icon 
          v-if="hasArrow"
          color="#959595"
          :is-responsive-name="true"
          :name="showDetail?'sui_icon_more_up_18px':'sui_icon_more_down_16px'" 
          @click="$emit('handleDetailChange', !showDetail)"
        />
      </div>
      <div
        v-show="showDetail"
        class="common-sizemeasure__detail"
      >
        <!-- <p>{{ language.SHEIN_KEY_PC_15302 }}</p> -->
        <div class="common-sizemeasure__detail-description">
          <div
            v-for="item in productInfo.sizeTemplate.description_multi"
            :key="item.name"
            class="common-sizemeasure__detail-description-item"
          >
            <h6>
              <em class="index-dot">{{ item.sort }}</em> {{ item.name }}
            </h6>
            <p>{{ item.description }}</p>
          </div>
        </div>
        <div class="common-sizemeasure__detail-pricture">
          <img
            :src="transformImg({ img: productInfo.sizeTemplate.image_url })"
          />
        </div>
      </div>
    </div>
    <div
      v-if="showMeasurement && measurement.length && tableStatus == 'base'"
      class="common-sizemeasure"
    >
      <div
        v-if="
          productIntroData.sizeInfoDes &&
            productIntroData.sizeInfoDes.basicAttribute &&
            productIntroData.sizeInfoDes.basicAttribute.image_url
        "
        class="common-sizemeasure__normal"
      >
        <p
          class="common-sizemeasure__detail-description-title"
          :style="{ fontSize: isModal ? '18px' : '', fontWeight: '400' }"
        >
          {{ language.SHEIN_KEY_PC_17725 }}
          <Icon 
            color="#959595"
            :is-responsive-name="true"
            :name="showDetail?'sui_icon_more_up_18px':'sui_icon_more_down_16px'" 
            @click="$emit('handleDetailChange', !showDetail)"
          />
        </p>
        <div 
          v-show="showDetail"
          class="common-sizemeasure__detail base"
        >
          <div
            class="common-sizemeasure__detail-description base"
            data-type="2"
          >
            <div
              v-for="(item, index) in measurement"
              :key="item.name"
              class="common-sizemeasure__detail-description-item"
            >
              <h6>
                <em class="index-dot">{{ index + 1 }}</em> {{ item.name }}
              </h6>
              <p>{{ item.desc }}</p>
            </div>
          </div>
          <div class="common-sizemeasure__detail-pricture base">
            <img
              :src="
                transformImg({
                  img: productIntroData.sizeInfoDes.basicAttribute.image_url,
                })
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import schttp from 'public/src/services/schttp'
import { debounce } from '@shein/common-function'
import 'public/src/icon/arrow-down.svg'
import 'public/src/icon/down_222.svg'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { formatSizeInfo } from '../utils/sizeInfoDesc'
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import eventBus from '../utils/eventBus'
import { cloneDeep } from 'lodash'
import { getPrioritySize } from 'public/src/pages/goods_detail_v2/utils/sizeInfoDesc.js'
import sizeGuideFromSceneMap from 'public/src/pages/goods_detail_v2/utils/sizeGuideFromSceneMap.js' 

daEventCenter.addSubscriber({ modulecode: '1-8-6' })
const { SiteUID, PUBLIC_CDN, WEB_CLIENT } = gbCommonInfo
export default {
  name: 'CommonSizeTable',
  components: {
    Icon,
  },
  props: {
    showDetail: {
      type: Boolean,
      default: true,
    },
    showMeasurement: {
      type: Boolean,
      default: false,
    },
    currentCountry: {
      type: String,
      default: '',
    },
    currentUnit: {
      type: Number,
      default: -1,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
    productIntroData: {
      default: null,
      type: Object,
    },
    saleAttrList: {
      type: Object,
      default: () => ({
        skc_name: '',
        skc_sale_attr: [],
        sku_list: [],
      }),
    },
    language: {
      default: () => {},
      type: Object,
    },
    sizePriority: {
      type: String,
      default: 'off',
    },
    isModal: Boolean,
    from: String,
    recommendedSizeData: {
      default: () => ({
        recommendedSize: null,
        braRecommendSizes: []
      }),
      type: Object,
    }
  },
  data() {
    return {
      PUBLIC_CDN,
      // currentSizeUnit: '',
      currentSelectedCountry: '',
      multiSelectOption: false,
      tableStatus: 'normal',
      selectMultiPart: '',
      // isSelectHide: false
      // 优先级tab信息
      initTableStatus: 'normal',
      showShadow: false,
    }
  },
  computed: {
    isJapan() {
      return SiteUID === 'jp'
    },
    goodsId() {
      return this.productInfo?.goods_id || ''
    },
    rtlStyle() {
      if (typeof gbCommonInfo === 'undefined') return {}
      const { GB_cssRight } = gbCommonInfo || {}
      return GB_cssRight ? { marginLeft: '48px' } : { marginRight: '48px' }
    },
    sizeInfoStrFilterField() {
      return [
        'size',
        'attr_id',
        'attr_name',
        'attr_value_id',
        'attr_value_name',
        'attr_value_name_en',
      ]
    },
    hasMeasurementData() {
      const { productInfo } = this
      return (
        productInfo.sizeTemplate &&
        (productInfo?.sizeTemplate?.image_url ||
          productInfo?.sizeTemplate?.description_multi?.length)
      )
    },
    // ...mapState(['productIntroData', 'isSelectHide']),
    currentSizeUnit: {
      get() {
        return this.currentUnit
      },
      set(val) {
        this.$emit('update:currentUnit', val)
      },
    },
    // 本地尺码
    localSizeList() {
      return this.productIntroData.localSizeList
    },
    // 尺寸描述信息
    sizeInfoDes() {
      return this.productIntroData?.sizeInfoDes || {}
    },
    productInfo() {
      return this.productIntroData.detail || {}
    },
    multiLocalSize() {
      return (
        (this.productIntroData.multiLocalSize &&
          this.productIntroData.multiLocalSize.size_rule_list) ||
        {}
      )
    },
    // 部分基码图size字段没数据
    hasSizeColumn() {
      return this.currSizeInfo.some((_) => _.size || _.attr_value_name)
    },
    tableTitle() {
      if (this.mode == 1 || this.mode == 2) {
        return this.mode == 2
          ? this.language.SHEIN_KEY_PC_17724
          : this.language.SHEIN_KEY_PC_17722
      }
      if ((this.mode == 0 || this.mode == 3) && this.sizeInfoMode.baseNoData) {
        return this.language.SHEIN_KEY_PC_17724
      }
      return ''
    },
    mode() {
      return this.sizeInfoDes.basicAttribute?.base_code_show_mode || 0
    },
    multiPartList() {
      return this.sizeInfoMode[this.unitType]['normal'].reduce((res, item) => {
        if (item.name && item.list.some((_) => Object.keys(_).length > 0)) {
          res.push(item)
        }
        return res
      }, [])
    },
    sizeInfoMode() {
      const showNomal = [0, 2, 3]
      const showBase = [0, 1, 3]
      let {
        normalCm,
        normalInch,
        baseCm,
        baseInch,
        baseOther,
        sizeInfoDesAttrName,
      } = formatSizeInfo(this.saleAttrList.skc_sale_attr, this.sizeInfoDes)
      let baseNoData = false
      if (baseOther[0].list.length > 0) {
        const len = Math.max(baseOther[0].list.length, baseCm[0].list.length)
        for (let i = 0; i < len; i++) {
          baseCm[0].list[i] = Object.assign(
            baseCm[0].list[i] || {},
            baseOther[0].list[i] || {}
          )
          baseInch[0].list[i] = Object.assign(
            baseInch[0].list[i] || {},
            baseOther[0].list[i] || {}
          )
        }
      }
      if (baseCm[0].list.length == 0 && baseInch[0].list.length == 0) {
        if (this.mode == 3) {
          baseInch = normalInch
          baseCm = normalCm
        }
        baseNoData = true
      }
      return {
        inch: {
          normal: showNomal.includes(this.mode) ? normalInch : [],
          base: showBase.includes(this.mode) ? baseInch : [],
        },
        cm: {
          normal: showNomal.includes(this.mode) ? normalCm : [],
          base: showBase.includes(this.mode) ? baseCm : [],
        },
        baseNoData,
        sizeInfoDesAttrName,
      }
    },
    // 表格头部部分
    tableHead() {
      if (!this.showBaseSize) return []
      // 处理国际尺码 && 本地尺码
      const result = []
      const sizeInfoDes =
        this.currentSizeUnit === 1
          ? this.sizeInfoDes.basicAttribute.base_code_info_inch
          : this.sizeInfoDes.basicAttribute.base_code_info
      if (
        this.currentSelectedCountry &&
        sizeInfoDes &&
        Object.keys(sizeInfoDes).length &&
        this.localsize &&
        Object.keys(this.localsize.size_rule_list).length &&
        this.localsize.size_rule_list[this.currentSelectedCountry]
      ) {
        for (const item of sizeInfoDes) {
          const temp = {
            value: item.size,
          }
          for (const subItem of this.localsize.size_rule_list[
            this.currentSelectedCountry
          ]) {
            if (item.size === subItem.name) {
              temp['local_size'] = subItem.correspond
              break
            }
          }
          result.push(temp)
        }
      } else if (sizeInfoDes && Object.keys(sizeInfoDes).length) {
        for (const item of sizeInfoDes) {
          const temp = {
            value: item.size,
          }
          result.push(temp)
        }
      }
      return result
    },
    hasLocalColumn() {
      const sizeInfoDes = this.currSizeInfo
      if (sizeInfoDes) {
        for (const i in sizeInfoDes) {
          const item = sizeInfoDes[i]
          if (
            this.currentSelectedCountry &&
            this.multiLocalSize[this.currentSelectedCountry] &&
            this.multiLocalSize[this.currentSelectedCountry].length
          ) {
            const find = this.multiLocalSize[
              this.currentSelectedCountry
            ]?.find?.((i) => i.name === (item.attr_value_name || item.size))
            if (find) {
              return true
            }
          }
        }
      }
      return false
    },
    //展示基码表
    showBaseSize() {
      const sizeInfo =
        this.currentSizeUnit !== 1
          ? this.sizeInfoDes.sizeInfo
          : this.sizeInfoDes.sizeInfoInch
      return (
        this.sizeInfoDes.multiPartFlag ||
        (sizeInfo && !!sizeInfo.length) ||
        false
      )
    },
    unitType() {
      return this.currentSizeUnit == 1 ? 'inch' : 'cm'
    },
    currMultiPart() {
      return this.selectMultiPart || this.multiPartList[0]
    },
    currSizeInfo() {
      let list = (this.sizeInfoDes.multiPartFlag && this.tableStatus == 'normal') ?
        ((this.sizeInfoMode[this.unitType][this.tableStatus].find(_ => _.code === this.currMultiPart?.code) || { list: [] }).list) :
        this.sizeInfoMode[this.unitType][this.tableStatus][0].list
      let result = []
      if (this.tableStatus === 'normal') {
        result = list
      } else {
        let baseAttrSort = this.sizeInfoDes.basicAttribute.base_size_sort || null
        result = baseAttrSort && list.sort((a, b) => baseAttrSort[a.size] - baseAttrSort[b.size])
      }
      return result
    },
    headName() {
      return (
        this.sizeInfoMode.sizeInfoDesAttrName ||
        this.language.SHEIN_KEY_PC_14656
      )
    },
    tableHeadBase() {   
      let sortKeys = []
      if (this.tableStatus === 'normal') {
        let dimensionAttrInfo = []
        if (this.sizeInfoDes?.multiPartFlag === 1) {
          dimensionAttrInfo = this.sizeInfoDes?.multiPartInfo?.find(item => item.multiPartCode === this.currMultiPart.code)?.dimensionAttrInfo || []
        } else {
          dimensionAttrInfo = this.sizeInfoDes?.dimensionAttrInfo ? [...this.sizeInfoDes.dimensionAttrInfo] : []
        }
        sortKeys = dimensionAttrInfo.sort((a, b) => {
          return a.sort - b.sort
        }).map((item) => { 
          return item.attr_name 
        }) 
      } else {
        let baseSizeSortMap =  cloneDeep(this.sizeInfoDes?.basicAttribute?.base_attr_sort || {})
        sortKeys = Object.keys(baseSizeSortMap).sort((a, b) => baseSizeSortMap[a] - baseSizeSortMap[b])
      }
      const result = sortKeys?.map((item) => { 
        return { value: item }
      }) || []

      result.unshift({
        value: this.headName
      })

      if (this.hasLocalColumn) {
        // 本地码换到第一行
        result.splice(0, 0, {
          value: this.currentSelectedCountry,
        })
      }
      return result
    },
    tableBodyBase() {
      const result = []
      const nullMap = {}
      for (const i in this.currSizeInfo) {
        const item = this.currSizeInfo[i]
        let baseSizeItem = []
        let find
        if (this.hasLocalColumn) {
          find =
            this.multiLocalSize[this.currentSelectedCountry]?.find?.(
              (i) => i.name === (item.attr_value_name || item.size)
            ) || {}
          if (find.name) {
            // 本地码放第一列
            baseSizeItem.push(find.correspond)
            baseSizeItem.push(find.name) // (${find.correspond})
          } else {
            baseSizeItem.push('/')
            baseSizeItem.push(item.attr_value_name || item.size)
          }
        } else {
          baseSizeItem.push(item.attr_value_name || item.size)
        }
        for (const h in this.tableHeadBase) {
          if ((!find && h == 0) || (find && h <= 1)) {
            if (!nullMap[h]) {
              nullMap[h] = true
            }
            continue
          }
          const headVal = this.tableHeadBase[h].value
          if (item[headVal]) {
            const v = item[headVal].replace(/(cm|inch)/g, '').trim()
            if (!nullMap[h] && v !== '/') {
              nullMap[h] = true
            }
            baseSizeItem.push(v || '/')
          }
        }
        find = null
        result.push(baseSizeItem)
      }
      return {
        nullMap,
        result: result.map(item => {
          // 有的小数精度过大，直接保留两位小数
          return item.map(v => v && typeof v === 'string' && (/^-?\d*\.\d{3,}$/.test(v)) ? parseFloat(v).toFixed(2) : v)
        }),
      }
    },
    measurement() {
      let arr =
        (this.productIntroData.sizeInfoDes &&
          this.productIntroData.sizeInfoDes.basicAttribute &&
          this.productIntroData.sizeInfoDes.basicAttribute &&
          this.productIntroData.sizeInfoDes.basicAttribute.attribute_info &&
          this.productIntroData.sizeInfoDes.basicAttribute.attribute_info
            .length &&
          this.productIntroData.sizeInfoDes.basicAttribute.attribute_info) ||
        []
      return arr.sort((curr, next) => {
        return curr.sort - next.sort
      })
    },
    parentCatShowMeasure() {
      const { parentCats = {} } = this.productIntroData
      if (!parentCats.children) return false
      for (const v of parentCats.children) {
        if (
          (v.cat_id == 1745 && WEB_CLIENT === 'shein') ||
          (v.cat_id == 668 && WEB_CLIENT === 'romwe')
        ) {
          return true
        }
      }
      return false
    },
    sizeTipsText() {
      return this.tableStatus == 'normal' ? 
        this.unitType === 'cm' ? this.language.SHEIN_KEY_PC_17723 : this.language.SHEIN_KEY_PC_32400
        : this.language.SHEIN_KEY_PC_17726
    },
    tableContainerWidth () {
      let tableData = this.tableBodyBase.result
      let itemWidth = 85
      let tableWidth = tableData[0].length * itemWidth
      let result = ''
      tableData.forEach(item => {
        if (item[0]?.length > 10) {
          result = itemWidth + tableWidth + 'px'
        }
        if (item[0]?.length > 60) {
          result = itemWidth * 2 + tableWidth + 'px'
        }
      })
      return result
    }
  },
  watch: {
    currentCountry: {
      handler(val) {
        this.currentSelectedCountry = val
      },
      immediate: true,
    },
    productIntroData() {
      // 商品变化，重置选中的多部件尺码
      this.selectMultiPart = ''
      if (!this.productIntroData?.localSizeList) return
      let sizeUnit = localStorage.getItem('selectedUnit')
      let country = localStorage.getItem('last_select_country')
      if (country === 'default') country = ''
      this.tableStatus = 'normal'
      this.currentSelectedCountry =
        country || this.productIntroData.localSizeList.country_code
      this.handleChangeCountry(this.currentSelectedCountry)
      this.currentSizeUnit = sizeUnit
        ? Number(sizeUnit)
        : Number(this.sizeInfoDes.sizeUnit)
    },
    goodsId() {
      this.initMode()
    },
    mode: {
      immediate: true,

      handler() {
        this.initMode()
      },
    },
    // isSelectHide () {
    //   this.multiSelectOption = false
    // }
  },
  mounted() {
    this.$nextTick(() => {
      this.bindEvent()
    })
  },
  methods: {
    // ...mapMutations(['assignState']),
    handleChangeCountry: debounce({
      func: function(val) {
        this.$emit('handleChangeCountry', val) 
      },
      wait: 2000,
      options: {
        trailing: false
      }
    }),
    initMode() {
      try {
        this.tableStatus = getPrioritySize(this.saleAttrList?.skc_sale_attr, this.sizeInfoDes, this.sizePriority)
        this.initTableStatus = this.tableStatus
      }catch (err) {
        console.log(err)
      }
    },
    sizeGuideModalShow() {
      const isFromProductIntro = this.from === 'productIntro'
      eventBus.emit('sizeGuideModalShow', isFromProductIntro ? 'pc_size_fit' : undefined)

      daEventCenter.triggerNotice({
        daId: '1-8-6-236',
        extraData: {
          from: sizeGuideFromSceneMap['pc_size_fit']
        }
      })

      sa('send', {
        activity_name: 'click_size_information'
      })
    },
    filterUnit(value) {
      if (!value) return ''
      return String(value).replace(/(cm|inch)/gi, '')
    },
    /**
     * 切换单位
     * 0 -> cm
     * 1 -> inch
     * @param {String} num 单位映射
     */
    handleChangeUnit(num) {
      if (this.currentSizeUnit === num) return
      localStorage.setItem('selectedUnit', num)
      this.currentSizeUnit = num
      this.$emit('change-unit', num)
    },
    bindEvent: function () {
      document.querySelectorAll('.j-common-size-table td')?.forEach(cell => {
        cell.addEventListener('mouseenter', function() {
          const colIndex = Array.from(this.parentNode.children).indexOf(this) + 1
          const rowIndex = Array.from(this.closest('tr').parentNode.children).indexOf(this.closest('tr')) + 1

          if (colIndex > 1) {
            const rows = this.closest('table').querySelectorAll('tr')
            for (let i = 0; i < rowIndex; i++) {
              const colCells = rows[i].querySelectorAll(`td:nth-child(${colIndex})`)
              colCells.forEach(colCell => colCell.classList.add('col-highlight'))
            }
          }
        })

        cell.addEventListener('mouseleave', function() {
          const table = this.closest('table')
          const highlightedCells = table.querySelectorAll('tr > .col-highlight')
          highlightedCells.forEach(highlightedCell => highlightedCell.classList.remove('col-highlight'))
        })
      })
    },
    changeTableStatus(val) {
      this.tableStatus = val
    },
    selectCountry(key) {
      this.currentSelectedCountry = key
      this.handleChangeCountry(key || 'default')
      localStorage.setItem('last_select_country', key || 'default')
      schttp({
        method: 'POST',
        url: '/api/common/busineseCache/update',
        data: {
          cacheData: key || 'default',
          scanType: 'detail_local_country'
        }
      })
    },
    transformImg,
    handleScroll(event) {
      this.showShadow = event.target.scrollLeft > 0
    },
    // 是否是推荐尺码
    isRecommendedSize(row) {
      const { recommendedSize, braRecommendSizes } = this.recommendedSizeData
      // 如果有本地尺码，第二列是标码，如果没有本地尺码，第一列是标码
      const size = this.hasLocalColumn ? row[1] : row[0]
      // braRecommendSizes 为内衣推荐尺码，为多个值
      return recommendedSize === size || (braRecommendSizes || []).includes(size)
    }
  },
  emits: [
    'update:currentUnit',
    'change-unit',
    'sizeGuideModalShow',
  ],
}
</script>

<style lang="less" scoped>
.common-sizetable {
  &__units {
    position: relative;
  }
  .sg-table__part {
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(224,224,224,1);
    }
    &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: rgba(247,248,250,1);
    }
  }
  .sg-table__part-btn {
    display: inline-block;
    margin-bottom: 10px;
    .sg-table__part-wrap {
      display: flex;
      height: 26px;
      margin-bottom: 5px;
    }
    li {
      flex: 0 0 auto;
      border: 1px solid #222;
      border-right: none;
      padding: 0 8px;
      line-height: 24px;
      cursor: pointer;
      &:last-of-type {
        border-right: 1px solid #222;
      }
    }
    .part-active {
      background: #222;
      color: white;
    }
  }
  .sg-table__part-line {
    margin-right: 10px;
    margin-bottom: 15px;
    .sg-table__part-wrap {
      display: flex;
      margin-bottom: 5px;
    }
    li {
      flex: 0 0 auto;
      border-bottom: 2px solid transparent;
      padding: 4px 0;
      margin-right: 24px;
      font-size: 13px;
      cursor: pointer;
      &:last-of-type {
        margin: 0;
      }
    }
    .part-active {
      border-bottom: 2px solid #222;
      font-weight: bold;
    }
  }
  &__out-wrap {
    display: flex;
    flex-direction: column;
    .common-sizetable__units-left {
      right: auto;
    }
  }
  &__baszGuide {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
    color: #222;
  }
  &__title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__title-s {
    position: relative;
    color: #767676;
    cursor: pointer;
    font-size: 18px;
    &.active {
      color: #222;
      &::after {
        content: '';
        height: 3px;
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        background: #222;
        /*rw:begin*/
        background: #ff696e;
      }
    }
    &:first-of-type {
      margin-right: 15%;
    }
  }
  &__baszGuide-new {
    font-size: 12px;
    color: #fff;
    display: inline-block;
    background: #5ebd66;
    padding: 0 5px;
    line-height: 16px;
    font-weight: 100;
  }
  &__baszGuide-icon {
    width: 0;
    height: 0;
    border: 4px solid transparent;
    .border-r(4px,#5ebd66);
    display: inline-block;
  }
  &__country {
    min-width: 85px;
    height: 24px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(229, 229, 229, 1);
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    .svgicon.rotate-up {
      transform: rotate(180deg);
    }
  }
  &__country-select {
    background: rgba(245, 244, 245, 1);
  }
  &__tip {
    color: #959595;
    margin-top: 10px;
  }
  &__tip-head {
    margin: -9px 0 15px 0;
  }
  &__more {
    color: @sui_color_link;
    font-size: 12px;
    font-family: ArialMT;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
  }
  &__notice {
    color: #999999;
  }
  &__guide {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
  }
  &__button {
    .padding-r(20px);
    width: 96px;
  }
  &__button-inner {
    direction: initial;
    display: flex;
  }
  &__button-item {
    flex: 0 0 38px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #fff;
    color: #222;
    border: 1px solid #222;
    cursor: pointer;
    text-transform: uppercase;
    /*rw:begin*/
    border-color: #ccc;
    &_active {
      background-color: #222;
      color: #fff;
      border: 1px solid #222;
      /*rw:begin*/
      border-color: #ff9999;
      background: #ffefed;
      color: #ff9999;
    }
  }
  &_icon {
    margin-left: 20px;
    transition: 0.3s all ease;
    width: 18px;
    height: 18px;
    &:before {
      font-weight: 700;
      font-size: 18px;
    }
  }
  &_icon-active {
    transform: rotateZ(180deg);
  }
}
.common-sizemeasure {
  font-size: 13px;
  color: #222;
  h5 {
    margin-bottom: 10px;
    color: #222;
    font-size: 14px;
    text-transform: none;
  }
  &__detail-picture-bed {
    width: 500px;
    margin: auto;
    img {
      width: 100%;
    }
  }
  &__normal {
    margin-top: 20px;
    border-top: 0.5px solid#E5E5E5;
    border-bottom: 0.5px solid#E5E5E5;
    > p {
      font-size: 14px;
      color: #000000;
    }
  }
  &__title {
    height: 56px;
    line-height: 56px;
    color: #000000;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > p {
      font-size: 18px;
      color: #222;
      font-weight: 600;
    }
  }
  &__detail {
    display: flex;
    justify-content: space-between;
    position: relative;
    white-space: nowrap;
    padding-bottom: 20px;
    > p {
      font-size: 13px;
      line-height: 15px;
      font-weight: 100;
    }
  }
  &__detail-pricture {
    width: 260px;
    display: inline-block;
    &.base {
      width: 200px;
    }
    img {
      width: 100%;
    }
  }
  &__detail-description {
    max-width: 340px;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    &.base {
      max-width: 400px;
    }
  }
  &__describe-space {
    width: 22%;
    height: 1px;
  }
  &__detail-description-title {
    height: 56px;
    line-height: 56px;
    color: #000000;
    display: flex;
    align-items: center;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
    justify-content: space-between;
  }
  &__detail-description-item {
    word-break: break-all;
    & + .common-sizemeasure__detail-description-item {
      margin-top: 23px;
    }
    h6 {
      font-size: 14px;
      text-transform: none;
      font-weight: 100;
      line-height: 29px;
      font-weight: 600;
      margin-bottom: 7px;
      .index-dot {
        width: 16px;
        height: 16px;
        line-height: 16px;
        background: #000;
        color: #fff;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
      }
    }
    > p {
      font-size: 12px;
      line-height: 14px;
      font-weight: 100;
      word-break: keep-all;
    }
    .des-num {
      .margin-r(8px);
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      background-color: #000;
      color: #fff;
      text-align: center;
    }
  }
}
.common-sizemeasure {
  &__sizeChart {
    line-height: 40px;
    margin-top: 20px;
  }
  &__sizeChart-size {
    margin-bottom: 5px;
    color: #999;
    cursor: pointer;
    span {
      .margin-r(25px);
      font-size: 14px;
      font-weight: 700;
      display: inline-block;
      min-width: 25px;
      text-align: center;
      padding-bottom: 2px;
      margin-bottom: 10px;
      line-height: 13px;
    }
  }
  &__sizeChart-chart {
    background: #f7f8fa;
    padding: 15px;
    padding-bottom: 5px;
  }
  &__sizeChart-local {
    color: #222;
    font-weight: 700;
    font-size: 14px;
    height: 25px;
  }
  &__sizeChart-data {
    min-width: 150px;
    display: inline-block;
    color: #222;
    margin-bottom: 10px;
    .margin-r(15px);
  }
  &__sizeChart-sizeSelect {
    color: #222;
    border-bottom: 2px solid #222;
  }
}
.common-sizetable__table-container{
  border-top: 1px solid #E5E5E5;
  border-left: 1px solid #E5E5E5;
  overflow: auto;
}
.common-sizetable__table-content{
  border-collapse: separate;
  min-width: 100%;
}
.common-sizetable__table-tr{
  &:first-child{
    background: #F6F6F6;
    min-height: 40px;
    td {
      padding: 6px 8px;
      overflow-wrap: break-word;
      white-space: normal !important;
    }
  }
  td {
    min-width: 72px;
    padding: 16px 8px;
    border: 0 none;
    border-right: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    white-space: nowrap;
  }
  td:first-child{
    position: sticky;
    left: 0;
    background: #F6F6F6;
  }
}
.common-sizetable__table-tr-yellow {
  td{
    color: #A86104 !important;
  }
}
.common-sizetable__table-td-shadow {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -20px;
    bottom: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.05) 100%);
  }
}
</style>
